// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// FontAwesome
$fa-font-path:"../webfonts";

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

// AdminLTE
//@import '~admin-lte/dist/css/adminlte.css';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
//@import "~@fortawesome/fontawesome-free/scss/brands.scss";
